import { preDeleteFuncForItemsWithCommonImages, onSaveFuncForItemsWithCommonImages } from './callbackFuncs';
import { buildCollection, buildEntityCallbacks } from 'firecms';
import { Timestamp } from 'firebase/firestore';

export interface Offer {
  image: string;
  offerType: string;
  email: string;
  name: string;
  organization: string;
  location: string;
  description: string;
  criteria: string;
  amount: string;
  applicationLink: string;
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
}

const linkCallbacks = buildEntityCallbacks({  
  onPreDelete: preDeleteFuncForItemsWithCommonImages('offerSubmissions'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImages('offerSubmissions'),
});

const offerSubmissionsCollection = buildCollection<Offer>({
  group: 'Data',
  initialSort: ['dateUpdated', 'desc'],
  path: 'offerSubmissions',
  name: 'Offer Submissions',
  singularName: 'Link',
  inlineEditing: true,
  callbacks: linkCallbacks,
  permissions: ({ authController }) => ({
    edit: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    delete: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    create: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
  }),
  properties: {
    image: {
      name: 'Image',
      description: 'A thumbnail image.',
      dataType: 'string',
      storage: {
        storagePath: 'dynamicImages',
        acceptedFiles: ['image/*'],
        fileName: (context: any) => {
          return context.file.name;
        },
      },
    },
    offerType: {
      name: 'Tags',
      description: 'Type of offer',
      dataType: 'string',
      enumValues: {
        scholarship: 'Scholarship',
        internship: 'Internship',
      },
    },
    email: {
      name: 'Email',
      dataType: 'string',
      validation: { required: true },
    },
    name: {
      name: 'Name',
      dataType: 'string',
      validation: { required: true },
    },
    organization: {
      name: 'Organization',
      dataType: 'string',
      validation: { required: true },
    },
    location: {
      name: 'Location',
      dataType: 'string',
      validation: { required: true },
    },
    description: {
      name: 'Description',
      dataType: 'string',
      validation: { required: true },
    },
    criteria: {
      name: 'Criteria',
      dataType: 'string',
    },
    amount: {
      name: 'Amount',
      dataType: 'string',
    },
    applicationLink: {
      name: 'Apply Link',
      dataType: 'string',
      validation: { required: true },
    },
    dateCreated: {
      name: 'Date Created',
      dataType: 'date',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      name: 'Date Updated',
      dataType: 'date',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
});

export default offerSubmissionsCollection;
