import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import PublishIcon from '@mui/icons-material/Publish';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar } from '@mui/material';
import { doc, updateDoc, Timestamp } from 'firebase/firestore';
import { currFirestore } from './App';

export interface ContentWallType {
  dateUpdated: Timestamp;
}

export default function ContentWall() {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);

  const contentItemsDoc = doc(currFirestore, 'staticDocs', `previewContentWall`);
  const contentItemsProductionDoc = doc(currFirestore, 'staticDocs', `liveContentWall`);

  const saveContentItems = () => {
    const update: Partial<ContentWallType> = {
      dateUpdated: Timestamp.fromMillis(Date.now()),
    };
    updateDoc(contentItemsDoc, update)
      .then(() => {
        handleOpenSnackbar('Changes will be ready to preview in about 3 minutes.');
      })
      .catch((err) => {
        handleOpenSnackbar(`Something went wrong. ${err?.message}`);
      });
  };

  const publishChanges = () => {
    handleClosePublishDialog();
    const update: Partial<ContentWallType> = {
      dateUpdated: Timestamp.fromMillis(Date.now()),
    };
    updateDoc(contentItemsProductionDoc, update)
      .then(() => {
        handleOpenSnackbar('Changes will be live in about 3 minutes.');
      })
      .catch((err) => {
        handleOpenSnackbar(`Something went wrong and the changes are NOT live. ${err?.message}`);
      });
  };

  const handleOpenPublishDialog = () => {
    setOpenPublishDialog(true);
  };

  const handleClosePublishDialog = () => {
    setOpenPublishDialog(false);
  };

  const handleOpenSnackbar = (msg: string) => {
    setOpenSnackbar(true);
    setSnackbarMessage(msg);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="publish-page">
      <p>
        Click "Rebuild Test Site" to rebuild <a href="https://caq-limitless-test.web.app/">https://caq-limitless-test.web.app</a> and push any recent changes. Click "Rebuild Production Site" to
        rebuild <a href="https://joinaccountingplus.com">joinaccountingplus.com</a> and push any recent changes.
      </p>
      <Button className="std-btn" variant="contained" color="primary" aria-label="publish" onClick={() => window.open('https://caq-limitless-test.web.app/')}>
        <PreviewIcon />
        <span className="label">Preview</span>
      </Button>
      <Button className="std-btn" variant="contained" color="primary" aria-label="publish" onClick={saveContentItems}>
        <SaveIcon />
        <span className="label">Rebuild Test Site</span>
      </Button>
      <Button className="std-btn" variant="contained" color="primary" aria-label="publish" onClick={handleOpenPublishDialog}>
        <PublishIcon />
        <span className="label">Rebuild Production Site</span>
      </Button>
      <Dialog open={openPublishDialog} onClose={handleClosePublishDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Push changes live?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to push your current changes live?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePublishDialog}>No</Button>
          <Button onClick={publishChanges}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{snackbarMessage}</span>}
        action={[
          <Button key="undo" color="secondary" size="small" onClick={handleCloseSnackbar}>
            CLOSE
          </Button>,
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </div>
  );
}
