import React from 'react';
import './Actions.css';
// import { Button } from '@mui/material';
// import { collection, getDocs, updateDoc, Timestamp, doc, getDoc, setDoc } from 'firebase/firestore';
// import { currFirestore } from './App';
// import { PlatformUser } from './collections/platformUsersCollection';

export default function Actions() {
  // const updateUserDates = () => {
  //   let count = 0;
  //   const users = collection(currFirestore, 'platformUsers');
  //   getDocs(users).then((querySnap) => {
  //     querySnap.forEach((docSnap) => {
  //       const user = docSnap.data() as PlatformUser;
  //       count++;
  //       console.log(count, user.email);
  //       updateDoc(docSnap.ref, {
  //         dateCreated: Timestamp.fromMillis(Date.now()),
  //         dateUpdated: Timestamp.fromMillis(Date.now()),
  //       });
  //     });
  //   });
  // };

  // const copyProdContentWallToStaging = () => {
  //   const contentItemsDoc = doc(currFirestore, 'staticDocs', `previewContentWall`);
  //   // const contentItemsDocBackup = doc(currFirestore, 'staticDocs', `previewContentWallBackup`);
  //   const contentItemsProductionDoc = doc(currFirestore, 'staticDocs', `liveContentWall`);

  //   getDoc(contentItemsProductionDoc).then((docSnap) => {
  //     const wall = docSnap.data() as any;
  //     console.log('wall', wall)
  //     setDoc(contentItemsDoc, wall);
  //   });
  // };

  return (
    <div className="actions-page">
      {/* <Button className="std-btn" variant="contained" color="primary" onClick={updateUserDates}>
        <span className="label">updateUserDates</span>
      </Button>       */}
      {/* <Button className="std-btn" variant="contained" color="primary" onClick={copyProdContentWallToStaging}>
        <span className="label">copyProdContentWallToStaging</span>
      </Button> */}
    </div>
  );
}
