import { currFirestore } from '../App';
import { getDoc, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { EntityOnSaveProps } from 'firecms';
import { ContentWallType } from '../ContentWall';

export const preDeleteFuncForItemsWithCommonImages = (collection: string) => {
  return async ({ entityId }: { entityId: string }) => {
    deleteDoc(doc(currFirestore, 'contentItems', entityId));
  };
};

export const onSaveFuncForItemsWithCommonImages = (collection: string) => {
  return (props: EntityOnSaveProps<any>) => {
    if (props.entityId) {
      // Updating wall items with any new updates.
      const contantWallDoc = doc(currFirestore, 'staticDocs', 'previewContentWall');

      getDoc(contantWallDoc).then((docSnap) => {
        const previewContentWallUpdate = docSnap.data() as ContentWallType;
        const contentRef: any = props.values;
        const updatedContentItems = previewContentWallUpdate.contentItems.map((item) => {
          if (item.contentRef?.docId === props.entityId) {
            const updatedItem = {
              ...item,
              contentRef: {
                ...item.contentRef,
                tags: contentRef.tags || [],
                image: contentRef.image || '',
                title: contentRef.title || '',
                link: contentRef.link || '',
              },
            };
            return updatedItem;
          }
          return item;
        });

        setDoc(doc(currFirestore, 'staticDocs', 'previewContentWall'), {
          contentItems: updatedContentItems,
        }).catch((err) => {
          console.error(`Something went wrong. ${err?.message}`);
        });
      });
    }
  };
};
