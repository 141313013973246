import { buildCollection } from 'firecms';
import { Timestamp } from 'firebase/firestore';

export interface Question {  
  text: string;    
  votes: number;
  isVisible: boolean;
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
}

const adviceQuestionsCollection = buildCollection<Question>({
  group: 'Data',
  initialSort: ['dateUpdated', 'desc'],
  path: 'adviceQuestions',
  name: 'Advice Questions',
  singularName: 'Advice Question',
  inlineEditing: true,
  permissions: ({ authController }) => ({
    edit: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    delete: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    // create: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
  }),
  properties: {
    text: {
      name: 'Text',
      dataType: 'string',      
    },    
    votes: {
      name: 'Votes',
      dataType: 'number',      
    },     
    isVisible: {
      name: 'Visible?',
      dataType: 'boolean',
      description: 'Should this be visible on Accounting+?',
    },           
    dateCreated: {
      name: 'Date Created',
      dataType: 'date',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      name: 'Date Updated',
      dataType: 'date',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
});

export default adviceQuestionsCollection;
