export const productionFirebaseCredentials = {
  apiKey: process.env.REACT_APP_PROD_API_KEY || "",
  authDomain: "caq-limitless.firebaseapp.com",
  projectId: "caq-limitless",
  storageBucket: "caq-limitless.appspot.com",
  messagingSenderId: "93952970160",
  appId: "1:93952970160:web:322a188d5f0e17d5381a75",
};

export const stagingFirebaseCredentials = {
  apiKey: process.env.REACT_APP_TEST_API_KEY || "",
  authDomain: "caq-limitless.firebaseapp.com",
  projectId: "caq-limitless",
  storageBucket: "caq-limitless.appspot.com",
  messagingSenderId: "93952970160",
  appId: "1:93952970160:web:322a188d5f0e17d5381a75",
  // authDomain: "caq-limitless-test-28d47.firebaseapp.com",
  // projectId: "caq-limitless-test-28d47",
  // storageBucket: "caq-limitless-test-28d47.appspot.com",
  // messagingSenderId: "3197719775",
  // appId: "1:3197719775:web:597ce576e6f9073bd0c56a",
  // measurementId: "G-EFWN6E57EJ",
};
