import { buildCollection } from 'firecms';
import { Timestamp } from 'firebase/firestore';

export interface PlatformEvent {
  tags: string[];
  title: string;
  organization: string;
  description: string;
  location: string;
  files: {
    label: string,
    file: string,
  }[]; // File urls.
  links: {
    label: string,
    link: string,
  }[];
  visibleOnAaccountingPlus: boolean;
  visibleOnResourceHub: boolean;
  submittedByEmail?: string;
  startDate?: Timestamp;  
  endDate?: Timestamp;  
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
}

const eventsCollection = buildCollection<PlatformEvent>({
  group: 'Data',
  initialSort: ['dateUpdated', 'desc'],
  path: 'events',
  name: 'Events',
  singularName: 'Event',
  inlineEditing: true,
  permissions: ({ authController }) => ({
    edit: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    delete: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    create: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
  }),
  properties: {
    tags: {
      dataType: 'array',
      name: 'Tags',
      description: 'Used for categorization',
      of: {
        dataType: 'string',
        enumValues: {                    
          townhall: 'Townhall',
          liveStream: 'Live Stream',
          virtual: 'Virtual',
          inPerson: 'In Person',
        },
      },
    },
    title: {
      name: 'Title',
      dataType: 'string',
      validation: { required: true },
    },
    location: {
      name: 'Location',
      dataType: 'string',      
    },    
    organization: {
      name: 'Organization',
      dataType: 'string',      
    },    
    description: {
      name: 'Description',
      dataType: 'string',
      validation: { required: true },
      markdown: true,
    },
    submittedByEmail: {
      name: 'Submitted By',
      dataType: 'string',            
    },    
    files: {
      dataType: 'array',
      name: 'Files',
      of: {
        dataType: 'map',
        properties: {
          label: {
            validation: { required: true },
            name: 'label',
            dataType: 'string',
          },
          file: {
            validation: { required: true },
            name: 'file',
            dataType: 'string',
            storage: {
              storagePath: 'resourceFiles',
              fileName: (context: any) => {
                return context.file.name;
              },
            },
          },
        },
      },
    },
    links: {
      dataType: 'array',
      name: 'Links',
      of: {
        dataType: 'map',
        properties: {
          label: {
            validation: { required: true },
            name: 'label',
            dataType: 'string',
          },
          link: {
            validation: { required: true },
            name: 'link',
            dataType: 'string',
          },
        },
      },
    },
    visibleOnAaccountingPlus: {
      name: 'Visible on Accounting+',
      dataType: 'boolean',
      description: 'Should this be visible on Accounting+?',
    },
    visibleOnResourceHub: {
      name: 'Visible on the Partner Resource Hub',
      dataType: 'boolean',
      description: 'Should this be visible on the Partner Resource Hub?',
    },
    startDate: {
      name: 'Start Date',
      dataType: 'date',
      validation: { required: true },
    },
    endDate: {
      name: 'End Date',
      dataType: 'date',
      validation: { required: true },
    },    
    dateCreated: {
      name: 'Date Created',
      dataType: 'date',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },    
    dateUpdated: {
      name: 'Date Updated',
      dataType: 'date',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
});

export default eventsCollection;
