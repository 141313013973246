import { preDeleteFuncForItemsWithCommonImages, onSaveFuncForItemsWithCommonImages } from './callbackFuncs';
import { buildCollection, buildEntityCallbacks } from 'firecms';
import { Timestamp } from 'firebase/firestore';

export interface Partner {
  image: string;
  partnerName: string;
  link: string;
  group: string[];
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
}

const partnerCallbacks = buildEntityCallbacks({  
  onPreDelete: preDeleteFuncForItemsWithCommonImages('partners'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImages('partners'),
});

const partnersCollection = buildCollection<Partner>({
  group: 'Data',
  initialSort: ['dateUpdated', 'desc'],
  path: 'partners',
  name: 'Partners',
  singularName: 'Partner',
  inlineEditing: true,
  callbacks: partnerCallbacks,
  permissions: ({ authController }) => ({
    edit: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    delete: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    create: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
  }),
  properties: {
    image: {
      name: 'Image',
      description: 'A thumbnail image.',
      dataType: 'string',
      storage: {
        storagePath: 'dynamicImages',
        acceptedFiles: ['image/*'],
        fileName: (context: any) => {
          return context.file.name;
        },
      },
    },
    partnerName: {
      name: 'Title',
      dataType: 'string',
      validation: { required: true },
    },
    link: {
      name: 'Link',
      dataType: 'string',
      validation: { required: true },
    },
    group: {
      name: 'Group',
      dataType: 'array',
      of: {
        dataType: 'string',
        enumValues: {
          caqGoverningBoardMemberFirm: 'BCAQ Governing Board Member Firm',
          accountingFirmPartner: 'Accounting Firm Partner',
          publicSectorPartner: 'Non-profit and Public Sector Partner',
          stateCpaSocietyPartner: 'State CPA Society Partner',
        },
      },
    },
    dateCreated: {
      name: 'Date Created',
      dataType: 'date',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      name: 'Date Updated',
      dataType: 'date',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
});

export default partnersCollection;
