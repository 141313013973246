import { preDeleteFuncForItemsWithCommonImages, onSaveFuncForItemsWithCommonImages } from './callbackFuncs';
import { buildCollection, buildEntityCallbacks } from 'firecms';
import { Timestamp } from 'firebase/firestore';
import { categorizationEnumValues, colorEnumValues } from '../enums';

export interface LongForm {
  tags: string[];
  image: string;
  title: string;
  content: string;
  backgroundColor: string;
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
}

const longFormCallbacks = buildEntityCallbacks({  
  onPreDelete: preDeleteFuncForItemsWithCommonImages('longForms'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImages('longForms'),
});

const longFormsCollection = buildCollection<LongForm>({
  group: 'Data',
  initialSort: ['dateUpdated', 'desc'],
  path: 'longForms',
  name: 'Long Forms',
  singularName: 'Long Form',
  inlineEditing: true,
  callbacks: longFormCallbacks,
  customId: true,
  permissions: ({ authController }) => ({
    edit: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    delete: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
    create: authController.extra?.roles.includes('admin') || authController.extra?.roles.includes('associate'),
  }),
  properties: {
    tags: {
      dataType: 'array',
      name: 'Tags',
      description: 'Used for categorization',
      of: {
        dataType: 'string',
        enumValues: {
          ...categorizationEnumValues,
        },
      },
    },
    image: {
      name: 'Image',
      description: 'A thumbnail image.',
      dataType: 'string',
      storage: {
        storagePath: 'dynamicImages',
        acceptedFiles: ['image/*'],
        fileName: (context: any) => {
          return context.file.name;
        },
      },
    },
    title: {
      name: 'Title',
      dataType: 'string',
      validation: { required: true },
    },
    content: {
      name: 'Content',
      description: `The body text for the article.`,
      dataType: 'string',
      markdown: true,
    },
    backgroundColor: {
      name: 'Background Color',
      dataType: 'string',      
      enumValues: {
        ...colorEnumValues,
      },
    },
    dateCreated: {
      name: 'Date Created',
      dataType: 'date',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      name: 'Date Updated',
      dataType: 'date',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
});

export default longFormsCollection;
